import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Accolades from "../components/Repeating/Accolades";
import CallToAction from "../components/Repeating/CTA";

import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  const content = [
    {
      image: data.business.childImageSharp.gatsbyImageData,
      title: "Business",
      href: "/business/",
    },
    {
      image: data.construction.childImageSharp.gatsbyImageData,
      title: "Construction",
      href: "/construction/",
    },
    // {
    //   image: data.criminalDefense.childImageSharp.gatsbyImageData,
    //   title: "Criminal Defense",
    //   href: "/criminal-defense/",
    // },
    {
      image: data.DUIDWAI.childImageSharp.gatsbyImageData,
      title: "DUI / DWAI",
      href: "/dui-dwai/",
    },
    {
      image: data.elderLaw.childImageSharp.gatsbyImageData,
      title: "Elder Law",
      href: "/elder-law/",
    },
    {
      image: data.estatePlanning.childImageSharp.gatsbyImageData,
      title: "Estate Planning",
      href: "/estate-planning/",
    },
    {
      image: data.familyLaw.childImageSharp.gatsbyImageData,
      title: "Family Law",
      href: "/family-law/",
    },
    {
      image: data.guardianshipConservatorship.childImageSharp.gatsbyImageData,
      title: "Guardianship & Conservatorship",
      href: "/estate-planning/guardianship-conservatorship/",
    },
    {
      image: data.medicaid.childImageSharp.gatsbyImageData,
      title: "Medicaid",
      href: "/long-term-elder-care-planning/",
    },
    {
      image: data.personalInjury.childImageSharp.gatsbyImageData,
      title: "Personal Injury",
      href: "/personal-injury/",
    },
    {
      image: data.probate.childImageSharp.gatsbyImageData,
      title: "Probate",
      href: "/probate/",
    },
    {
      image: data.realEstate.childImageSharp.gatsbyImageData,
      title: "Real Estate",
      href: "/real-estate/",
    },
  ];
  return (
    <Layout>
      <SearchEngineOptimization
        title="Practice Areas | Colorado Lawyers"
        description="Practice Areas . Colorado attorneys at Jorgensen, Brownell & Pepin, P.C. help clients pursue maximum results. Call now for a consultation."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="relative pb-20 md:pb-28">
        <div className="container">
          <div className="grid md:grid-cols-2 gap-y-8">
            <div className="md:pt-32 md:pb-14 md:pr-18 order-2 md:order-1">
              <div className="bg-gray-900 text-gray-300 uppercase px-2.5 py-1.5 inline-flex items-center justify-center mb-5 md:mb-6">
                What We Do
              </div>
              <h1>Practice Areas</h1>
              <div className="md:pl-8 lg:pl-14 md:pt-3.5">
                <p className="mb-8">
                  At Jorgensen, Brownell & Pepin, P.C., we get desirable
                  outcomes for individuals, families, and businesses. As a
                  full-service law firm with a long track record of success, our
                  Colorado lawyers are known for achieving favorable results in
                  and out of the courtroom. We have years of experience and a
                  tradition of excellence to help clients succeed.
                </p>
                <ButtonSolid
                  modal="modal-contact"
                  text="Contact Us"
                  className="w-full md:w-auto"
                />
              </div>
            </div>
            <div className="order-1 md:order-2 relative md:h-full -mx-4 md:mx-0">
              <div className="md:absolute md:left-0 md:w-[50vw] h-full">
                <GatsbyImage
                  image={data.hero.childImageSharp.gatsbyImageData}
                  className="w-full h-full"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-20 md:pb-18">
        <div className="container">
          <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-y-8 md:gap-y-12 md:gap-x-6">
            {content.map((item, i) => (
              <div key={i}>
                <AniLink fade to={item.href} className="group no-underline">
                  <div className="overflow-hidden mb-3.5">
                    <GatsbyImage
                      image={item.image}
                      className="w-full transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
                    />
                  </div>
                  <div className="font-heading text-secondary-50 text-lg mb-0">
                    {item.title}
                  </div>
                </AniLink>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Accolades />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Practice Hub.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Practice Hub.jpg" }
    ) {
      publicURL
    }
    hero: file(relativePath: { eq: "2.0 Services Hub/hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 685)
      }
    }
    business: file(relativePath: { eq: "2.0 Services Hub/1.0 Business.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 564)
      }
    }
    construction: file(
      relativePath: { eq: "2.0 Services Hub/2.0 Construction.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 564)
      }
    }
    criminalDefense: file(
      relativePath: { eq: "2.0 Services Hub/4.0 Criminal Defense.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 564)
      }
    }
    DUIDWAI: file(relativePath: { eq: "2.0 Services Hub/5.0 DUI-DWAI.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 564)
      }
    }
    elderLaw: file(relativePath: { eq: "2.0 Services Hub/6.0 Elder Law.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 564)
      }
    }
    estatePlanning: file(
      relativePath: { eq: "2.0 Services Hub/7.0 Estate Planning.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 564)
      }
    }
    familyLaw: file(
      relativePath: { eq: "2.0 Services Hub/8.0 Family Law.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 564)
      }
    }
    guardianshipConservatorship: file(
      relativePath: {
        eq: "2.0 Services Hub/9.0 Guardianship _ Conservatorship.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 564)
      }
    }
    intellectualProperty: file(
      relativePath: { eq: "2.0 Services Hub/10.0 Intellectual Property.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 564)
      }
    }
    medicaid: file(relativePath: { eq: "2.0 Services Hub/11.0 Medicaid.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 564)
      }
    }
    personalInjury: file(
      relativePath: { eq: "2.0 Services Hub/12.0 Personal Injury.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 564)
      }
    }
    probate: file(relativePath: { eq: "2.0 Services Hub/13 Probate.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 564)
      }
    }
    realEstate: file(
      relativePath: { eq: "2.0 Services Hub/14 Real Estate.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 564)
      }
    }
  }
`;

export default Page;
